import { AppBar, Box, Paper, Tab, Tabs, tabsClasses, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { TabPanel, TabProps } from '../../../components/ui/TabPanel';
import { MenuitemSettings } from './MenuitemSettings';
import { ModulSettings } from './ModulSettings';
import { PageSettings } from './PageSettings';
import { RoleSettings } from './RoleSettings';
import { useLocation } from 'react-router-dom';

export const AdminSettings = () => {
  const theme = useTheme();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = parseInt(queryParams.get('menutab')) || 0;
  const [tabIndex, setTabIndex] = useState(tabFromUrl);

  const handleTabChange = (newValue) => {
    setTabIndex(newValue);
    const newUrl = `${location.pathname}?menutab=${newValue}`;
    window.history.replaceState(null, '', newUrl);
  };

  return (
    <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, width: '100%', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static" sx={
          {
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black",
          }
        }>
          <Tabs
            value={tabIndex}
            onChange={(_, index) => handleTabChange(index)}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            aria-label="admin settings tabs"
            scrollButtons
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            {["Page", "Role", "Menu", "Module", "Item 4", "Item 5", "Item 6", "Item 7"].map((name, index) => (
              <Tab key={index} label={name} sx={{ fontWeight: "bold" }} {...TabProps(index)} />
            ))}
            {/* <Tab key={0} label="Page" sx={{ fontWeight: "bold" }} {...TabProps(0)} />
            <Tab key={1} label="Menu" sx={{ fontWeight: "bold" }} {...TabProps(1)} />
            <Tab key={2} label="Role" sx={{ fontWeight: "bold" }} {...TabProps(2)} />
            <Tab key={3} label="Module" sx={{ fontWeight: "bold" }} {...TabProps(3)} />
            <Tab key={4} label="Item 4" sx={{ fontWeight: "bold" }} {...TabProps(4)} />
            <Tab key={5} label="Item 5" sx={{ fontWeight: "bold" }} {...TabProps(5)} />
            <Tab key={6} label="Item 6" sx={{ fontWeight: "bold" }} {...TabProps(6)} />
            <Tab key={7} label="Item 7" sx={{ fontWeight: "bold" }} {...TabProps(7)} /> */}
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <PageSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RoleSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <MenuitemSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ModulSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        Item 4
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        Item 5
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        Item 6
      </TabPanel>
      <TabPanel value={tabIndex} index={7}>
        Item 7
      </TabPanel>
    </Paper>
  )
}