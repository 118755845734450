import React, { useState, useCallback } from "react";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";


/**
 * Generic function for handeling common api calls.
 * Handels common server error responses (soon)
 */
export const useApi = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();

  const api = async (path: string, method: string = "GET", body?: object): Promise<any> => {
    setIsLoading(true);
    setError('');

    var token = localStorageService.getItem(LocalStorageKeys.TOKEN);

    if (!path.startsWith("/api/")) {
      const trimmedPath = path.split('/').filter(part => part !== '').join('/');
      path = `/api/${trimmedPath}`;
    }

    try {
      const options: RequestInit = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (method !== "GET" && body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(path, options);
      const json = await response.json();
      const statusCode = response.status ?? 0;
      json.statusCode = statusCode;

      if (!response.ok) {
        let message = json?.error?.message ? json.error.message : "Status: " + response.status;
        // if (response.status === 500) {
        //   setError("Error during processing of request")
        // } else {
        setError(message);
        // }
      } else {
        setResponse(json);
      }

      setIsLoading(false);
      return json;
    } catch {
      setError("Noe gikk galt, prøv igjen senere.");
      setIsLoading(false);
      const json: any = {
        error: "Noe gikk galt, prøv igjen senere.",
        statusCode: 500,
      }
      return json;
    }
  };

  return { api, response, isLoading, error };
};

export const useMultipartApi = () => {
  const [mError, setError] = useState<string>("");
  const [mIsLoading, setIsLoading] = useState<boolean>(false);
  const [mResponse, setResponse] = useState<any>();

  const multipartApi = async (path: string, formData: FormData): Promise<any> => {
    setIsLoading(true);
    setError('');

    var token = localStorageService.getItem(LocalStorageKeys.TOKEN);

    if (!path.startsWith("/api/")) {
      const trimmedPath = path.split('/').filter(part => part !== '').join('/');
      path = `/api/${trimmedPath}`;
    }

    try {
      const options: RequestInit = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      options.body = formData;

      const response = await fetch(path, options);
      const json = await response.json();
      const statusCode = response.status ?? 0;
      json.statusCode = statusCode;

      if (!response.ok) {
        let message = json?.error?.message ? json.error.message : "Status: " + response.status;
        if (response.status === 500) {
          setError("Error during processing of request")
        } else {
          setError(message);
        }
      } else {
        setResponse(json);
      }

      setIsLoading(false);
      return json;
    } catch {
      setError("Noe gikk galt, prøv igjen senere.");
      setIsLoading(false);
      return;
    }
  };

  return { multipartApi, mResponse, mIsLoading, mError };
};






// export const usePostQuery = <BodyData, ResponseData>(
//   headers?: HeadersInit
// ): {
//   post: (path: string, data: BodyData) => Promise<void>;
//   isLoading: boolean;
//   error: string | null;
//   responseData: ResponseData | null;
// } => {
//   const [responseData, setResponseData] = useState<ResponseData | null>(null);
//   const [isLoading, setIsLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   const post = useCallback(
//     async (path: string, data: BodyData) => {
//       setError(null)
//       try {
//         console.log("usePostQuery path :", path, "body :", data);
//         setIsLoading(true);
//         const response = await fetch("/api" + path, {
//           method: "POST",
//           body: JSON.stringify(data),
//           headers,
//         });

//         const json = await response.json();
//         if (!response.ok) {
//           setError(json.error.message);
//         }
//         if (response.ok) {
//           setResponseData(json);
//         }
//         setIsLoading(false);
//       } catch (error: any) {
//         setError(error.message);
//         setIsLoading(false);
//       }
//     },
//     [headers]
//   );

//   return { responseData, isLoading, error, post };
// };