import React from 'react';
import Header from './navigations/Header';
import Header2 from './navigations/Header2';

const TopBarLayout = ({ children }: any) => {
  const style = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "calc(10px + 2vmin)",
  }

  return (
    <>
      <Header2 />
      <main>
        <div style={style as React.CSSProperties}>
          {children}
        </div>
      </main>
    </>
  );
}

export default TopBarLayout;