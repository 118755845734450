import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { Accessgroup } from '../../../model/System';
import { DashedTreeItem } from '../../../components/ui/DashedTreeItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SimpleTreeView } from '@mui/x-tree-view';
import DeleteIcon from '@mui/icons-material/Delete';
import HardDeleteDialog from '../../../components/modals/dialog/HardDeleteDialog';
import { CheckBox } from '@mui/icons-material';


export const RoleSettings = () => {
  const theme = useTheme();
  const { api, response, error, isLoading } = useApi();
  const [flatAccessgroups, setFlatAccessgroups] = useState<Accessgroup[]>([]);
  const [editAccessgroup, setEditAccessgroup] = useState<Accessgroup>(null);
  const [nameError, setNameError] = useState(false);
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]);
  const [expandedAccessgroups, setExpandedAccessgroups] = useState<string[]>([]);
  const [selected, setSelected] = useState<Accessgroup>({} as Accessgroup);
  const [openDelete, setOpenDelete] = useState(false);

  const editRef = useRef(null); // scroll to edit on edit click
  const executeScroll = () => editRef.current.scrollIntoView();

  useEffect(() => {
    api("/accessgroup");
    clearAccessgroup();
  }, []);

  useEffect(() => {
    if (response?.statusCode === 200) {
      setFlatAccessgroups(response?.accessgroups);
      createAccessgroupHirarchy(response);
    }
  }, [response])

  const createAccessgroupHirarchy = (res) => {
    const accessgroupMap = new Map<number, Accessgroup>();

    res?.accessgroups?.forEach(item => {
      let children: Accessgroup[] = [];
      item.children = children;
      item.isAccessgroup = true;
      accessgroupMap.set(item.id, item);
    });

    const hierarchy: Accessgroup[] = [];

    res?.accessgroups?.forEach(item => {
      if (item.parent_id === 0) {
        hierarchy.push(item);
      } else {
        const parent = accessgroupMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setAccessgroups(hierarchy);
  }

  const clearAccessgroup = () => {
    setEditAccessgroup({
      id: 0,
      name: "",
      parent_id: 0,
      employee: false,
      member: false,
      tillitsvalgt: false,
      company: false,
      status: 0,
      isAccessgroup: true,
      selectedStatus: "",
      children: null,
    });
    setNameError(false);
  }

  const postAccessgroup = () => {
    if (editAccessgroup.name.trim() === "") {
      setNameError(true);
      return;
    }

    api("/accessgroup", "POST", editAccessgroup).then(res => {
      if (res.statusCode === 200) {
        clearAccessgroup();
      }
    });
  }

  const handleExpandedAccessgroupsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedAccessgroups(itemIds);
  };

  const handleExpandAccessgroupsClick = () => {
    setExpandedAccessgroups((oldExpanded) =>
      oldExpanded.length === 0
        ? flatAccessgroups.map(v => v.name)
        : []
    );
  };

  const handleSettingsClick = (event, accessgroup) => {
    event.stopPropagation();
    setEditAccessgroup(accessgroup);
    setNameError(false);
    executeScroll();
  }

  const handleDelete = () => {
    api("/accessgroup/" + selected.id, "DELETE").then(() => {
      setSelected({} as Accessgroup);
      setOpenDelete(false);
    });
  }

  const handleDeleteClick = (event, accessgroup) => {
    event.stopPropagation();
    setSelected(accessgroup);
    setOpenDelete(true);
  }

  function recursiveMenuItem(accessgroup: Accessgroup, level = 0): ReactElement[] {
    const name = "- ".repeat(level) + accessgroup.name;
    const items = [
      <MenuItem key={accessgroup.id} value={accessgroup.id} disabled={accessgroup.id === editAccessgroup.id}>
        {name}
      </MenuItem>
    ];

    if (accessgroup.children && accessgroup.children.length > 0) {
      accessgroup.children.forEach(child => {
        items.push(...recursiveMenuItem(child, level + 1));
      });
    }

    return items;
  }

  function recursiveTree(item: Accessgroup) {
    return (
      <Box key={item.name}>
        <DashedTreeItem
          sx={{ width: '100%' }}
          itemId={item.name}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body1'>{item.name}</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title="Edit" onClick={(event) => handleSettingsClick(event, item)}>
                  <IconButton sx={{ p: 0 }} color='inherit' size="small">
                    <ManageAccountsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" onClick={(event) => handleDeleteClick(event, item)}>
                  <IconButton sx={{ p: 0 }} color='inherit' edge="end" size="small">
                    <DeleteIcon color='error' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          }
        >
          {item.children.map(child => recursiveTree(child))}
        </DashedTreeItem>
      </Box>
    )
  }

  return (
    <>
      <HardDeleteDialog
        open={openDelete}
        bodyText={`Are you sure you want to delete accessgroup "${selected?.name}"${selected?.children?.length > 0 ? ", this will also delete its children" : ""}? Click "DELETE" to confirm.`}
        loading={isLoading}
        errorMessage={error}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => handleDelete()}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <Typography variant='h5' sx={{ mt: 2 }}>Role settings</Typography>

        {error && <Alert sx={{ width: "100%" }} severity="error">{error}</Alert>}

        <Box ref={editRef} sx={{ width: "100%" }}>
          {editAccessgroup && (
            <InfoArea title={editAccessgroup.id > 0 ? "Edit accessgroup" : "Create accessgroup"}>
              <TextField
                label="Name"
                required
                value={editAccessgroup.name}
                onChange={(e) => {
                  setEditAccessgroup({ ...editAccessgroup, name: e.target.value })
                  e.target.value.trim() === "" ? setNameError(true) : setNameError(false);
                }}
                variant="standard"
                error={nameError}
                helperText={nameError ? "Name cannot be empty" : ""}
              />
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="select-parrent-group">Parrent group</InputLabel>
                <Select
                  labelId="select-parrent-group"
                  id="select-parrent-group"
                  value={editAccessgroup.parent_id}
                  onChange={(e) => setEditAccessgroup({ ...editAccessgroup, parent_id: typeof e.target.value === "string" ? parseInt(e.target.value) : e.target.value })}
                  label="Parrent group"
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  {accessgroups.map(accessgroup => recursiveMenuItem(accessgroup))}
                </Select>
              </FormControl>
              <FormGroup sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.employee}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, employee: e.target.checked})}
                    />}
                  label="Employee"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.member}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, member: e.target.checked})}
                    />}
                  label="Member"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.tillitsvalgt}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, tillitsvalgt: e.target.checked})}
                    />}
                  label="Tillitsvalgt"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.company}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, company: e.target.checked})}
                    />}
                  label="Company"
                />
              </FormGroup>

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 2 }}>
                {editAccessgroup.id > 0 && (
                  <Button variant='contained' color='darkgrey' onClick={clearAccessgroup}>Cancel</Button>
                )}
                <Button
                  variant='contained'
                  color='info'
                  onClick={postAccessgroup}
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={22} />}
                >
                  {editAccessgroup.id > 0 ? "Update" : "Create"}
                </Button>
              </Box>
            </InfoArea>
          )}
        </Box>

        <InfoArea title='Access groups'>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button variant='outlined' onClick={handleExpandAccessgroupsClick} sx={{ width: 150, fontWeight: "bold" }}>
              {expandedAccessgroups.length === 0 ? 'Expand all' : 'Collapse all'}
            </Button>
          </Box>
          <SimpleTreeView
            expandedItems={expandedAccessgroups}
            onExpandedItemsChange={handleExpandedAccessgroupsChange}
          >
            {accessgroups?.map((accessgroup: Accessgroup) => (
              recursiveTree(accessgroup)
            ))}
          </SimpleTreeView>
        </InfoArea>
      </Box>
    </>
  )
}