import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id: number, selected: readonly number[], theme: Theme) {
  const isSelected = selected.includes(id)
  return {
    fontWeight: isSelected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
    backgroundColor: isSelected
      ? theme.palette.primary.main
      : 'inherit',
  };
}

interface multiSelectProps {
  label: string;
  inputs: any[];
  selectField: string;
  selected: any[];
  onChange: (ids: number[]) => void
}

export const MultipleSelectChip: React.FC<multiSelectProps> = ({ label, inputs, selectField, selected, onChange }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const { value } = event.target;
    const selectedIds = typeof value === 'string' ? value.split(',').map(Number) : value;
    onChange(selectedIds);
  };

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selectedIds) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedIds.map((id) => {
              const selectedItem = inputs.find((input) => input.id === id);
              return selectedItem ? (
                <Chip key={id} label={selectedItem[selectField]} />
              ) : null;
            })}
          </Box>
        )}
        // MenuProps={MenuProps}
      >
        {inputs?.map((input) => (
          <MenuItem
            key={input.id}
            value={input.id}
            style={getStyles(input.id, selected, theme)}
          >
            {input.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}