import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as page from '../pages';
import * as admin from '../pages/admin';
import TopBarLayout from '../components/layouts/TopBarLayout';
import FullLayout from '../components/layouts/FullLayout';
import { authStore } from '../App';
import { Menuitem } from '../model/System';

const pathToPage = new Map<string, JSX.Element>([
  ["/organisasjon", <page.Organisasjon />],
  ["/medlem", <page.Medlem />],
  ["/bedrift", <page.Bedrift />],
  ["/tillitsverv", <page.Tillitsverv />],
  ["/stipend", <page.Stipend />],
  ["/tariff", <page.Tariff />],
  ["/økonomi", <page.Økonomi />],
  ["/kommunikasjon", <page.Kommunikasjon />],

  ["/admin/table", <admin.TableOverview />],
  ["/admin/manageMembers", <admin.ManageMembers />],
  ["/admin/settings", <admin.AdminSettings />],
  ["/admin/databasestate", <admin.DatabaseState />],
]);

export const AuthenticatedRoutes = () => {
  const {routes} = authStore();

  function contructAuthRoutes(menuitems: Menuitem[]): ReactElement[] {
    let availableRoutes: ReactElement[] = [];

    if (menuitems) {
      menuitems.sort((a, b) => a.priority - b.priority);
      
      menuitems.forEach(menuitem => {
        const elem = pathToPage.get(menuitem.url);
        if (elem) {
          availableRoutes.push(<Route key={menuitem.id} path={menuitem.url} element={elem} />)
        }
  
        if (menuitem.children.length > 0) {
          availableRoutes.push(...contructAuthRoutes(menuitem.children));
        }
      })
    }

    return availableRoutes;
  }

  return (
    <>
      <TopBarLayout>
        <Routes>
          <Route path="/" element={<page.Home />} />
          <Route path="/home" element={<page.Home />} />

          {contructAuthRoutes(routes)}

          {/* <Route path="/organisasjon" element={<page.Organisasjon />} />
          <Route path="/medlem" element={<page.Medlem />} />
          <Route path="/bedrift" element={<page.Bedrift />} />
          <Route path="/tillitsverv" element={<page.Tillitsverv />} />
          <Route path="/stipend" element={<page.Stipend />} />
          <Route path="/tariff" element={<page.Tariff />} />
          <Route path="/økonomi" element={<page.Økonomi />} />
          <Route path="/kommunikasjon" element={<page.Kommunikasjon />} /> */}

          <Route path="/profile" element={<page.Profile />} />
          <Route path="/Settings" element={<page.Settings />} />

          {/** Admin routes */}
          {/* <Route path="/admin/*" element={<Navigate to='table' replace />} /> */}
          <Route path="/admin/*" element={<page.MissingPermission />} />

          {/** @todo: on prod, replace these with <Route path="/*" element={<Navigate to='/' replace />} /> */}
          <Route path="/:path" element={<page.NotFound />} />
          <Route path="/*" element={<page.NotFound />} />
        </Routes>
      </TopBarLayout>
    </>
  );
};

const AdminRoutes = () => {
  const { userRoles } = authStore();
  return (
    <Routes>
      {userRoles.includes("admin") ? (
        <>
          <Route path="/table" element={<admin.TableOverview />} />
          <Route path="/manageMembers" element={<admin.ManageMembers />} />
          <Route path="/settings" element={<admin.AdminSettings />} />
          <Route path="/databasestate" element={<admin.DatabaseState />} />
          <Route path="/*" element={<Navigate to='table' replace />} />
        </>
      ) : (
        <Route path="/*" element={<page.MissingPermission />} />
      )}
    </Routes>
  );
};