import React from 'react';
import { TextField, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';

dayjs.locale('nb');

interface SingleDatePickerProps {
  label: string;
  date: Date | null;
  onChange: (date: Date | null) => void;
}

export const SingleDatePicker: React.FC<SingleDatePickerProps> = ({ label, date, onChange }) => {
  const theme = useTheme();

  const handleDateChange = (selectedDate: Dayjs | null) => {
    onChange(selectedDate ? selectedDate.toDate() : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
      <DatePicker
        label={label}
        value={date ? dayjs(date) : null}
        onChange={handleDateChange}
        slotProps={{
          field: { clearable: true },
          openPickerButton: {
            sx: {
              color: theme.palette.mode === 'dark' ? 'white' : 'black', 
            },
          },
          clearButton: {
            sx: {
              color: theme.palette.mode === 'dark' ? 'white' : 'black', 
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};