export interface Menuitem {
  description: string;
  id: number;
  level: number;
  license_paid: boolean;
  name: string;
  parent_id: number;
  priority: number;
  status: number;
  sys_form_id: number;
  updated_at: Date;
  updated_by: string;
  url: string;
  validated_at: Date;
  isMenuitem: boolean;
  selectedStatus: string;
  children: Menuitem[];
}

export interface Accessgroup {
  id: number;
  name: string;
  parent_id: number;
  employee: boolean;
  member: boolean;
  tillitsvalgt: boolean;
  company: boolean;
  status: number;
  isAccessgroup: boolean;
  selectedStatus: string;
  children: Accessgroup[];
}

export interface AccessgroupMenuitem {
  id: number;
  name: string;
  accessgroup_id: number;
  sys_menuitem_id: number;
  status: number;
}

export interface MenuSettings {
  fields: any;
  forms: any;
  menuitems: Menuitem[];
  accessgroups: Accessgroup[];
  accessgroupMenuitems: AccessgroupMenuitem[];
}

export function createMenuitemHirarchy(menuitems: Menuitem[]): Menuitem[] {
  const menuItemMap = new Map<number, Menuitem>();

  // Initialize the map and set the children array for each item
  menuitems.forEach(item => {
    let children: Menuitem[] = [];
    item.children = children;
    item.isMenuitem = true;
    menuItemMap.set(item.id, item);
  });

  const hierarchy: Menuitem[] = [];

  menuitems.forEach(item => {
    if (item.parent_id === 0) {
      // no parent
      hierarchy.push(item);
    } else {
      // Find the parent in the map and add the item to its children
      const parent = menuItemMap.get(item.parent_id);
      if (parent) {
        parent.children.push(item);
      }
    }
  });

  // Sort each level by priority
  function sortHierarchy(items: Menuitem[]) {
    items.sort((a, b) => a.priority - b.priority);
    items.forEach(item => sortHierarchy(item.children));
  }

  sortHierarchy(hierarchy);
  return hierarchy;
}