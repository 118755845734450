import dayjs from 'dayjs';

interface DayjsService {
  parseDate(input: string | dayjs.Dayjs | Date): dayjs.Dayjs;
  formatDate(input: string | dayjs.Dayjs, format: string): string;
  toUtcDatetimeString(input: string | dayjs.Dayjs): string;
  toUtcDateString(input: string | dayjs.Dayjs): string;
  toDisplayString(input: string | dayjs.Dayjs): string;
  toDateDisplayString(input: string | dayjs.Dayjs | Date): string;
}

export const dayjsService: DayjsService = {
  parseDate(input: string | dayjs.Dayjs | Date): dayjs.Dayjs {
    return dayjs(input);
  },

  formatDate(input: string | dayjs.Dayjs, format: string): string {
    const date = this.parseDate(input);
    return date.format(format);
  },
  
  toUtcDatetimeString(input: string | dayjs.Dayjs): string {
    const date = this.parseDate(input);
    if (!date.isValid()) {
      return '';
    }
    const YYYY = date.year();
    const MM = String(date.month() + 1).padStart(2, '0');
    const DD = String(date.date()).padStart(2, '0');
    const hh = String(date.hour()).padStart(2, '0');
    const mm = String(date.minute()).padStart(2, '0');
    const ss = String(date.second()).padStart(2, '0');

    return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}Z`;
  },
  
  toUtcDateString(input: string | dayjs.Dayjs): string {
    const date = this.parseDate(input);
    if (!date.isValid()) {
      return '';
    }
    const YYYY = date.year();
    const MM = String(date.month() + 1).padStart(2, '0');
    const DD = String(date.date()).padStart(2, '0');

    return `${YYYY}-${MM}-${DD}T$00:00:00Z`;
  },
  
  toDisplayString(input: string | dayjs.Dayjs): string {
    const date = this.parseDate(input);
    if (!date.isValid()) {
      return '';
    }
    const YYYY = date.year();
    const MM = String(date.month() + 1).padStart(2, '0');
    const DD = String(date.date()).padStart(2, '0');
    const hh = String(date.hour()).padStart(2, '0');
    const mm = String(date.minute()).padStart(2, '0');
    const ss = String(date.second()).padStart(2, '0');

    return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}`;
  },
  
  toDateDisplayString(input: string | dayjs.Dayjs | Date): string {
    const date = this.parseDate(input);
    if (!date.isValid()) {
      return '';
    }
    const YYYY = date.year();
    const MM = String(date.month() + 1).padStart(2, '0');
    const DD = String(date.date()).padStart(2, '0');

    return `${DD}.${MM}.${YYYY}`;
  },
};
