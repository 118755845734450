import React, { useEffect, useState } from 'react';
import BaseDataGridTable from '../../../components/tables/BaseDataGridTable';
import { GridColDef } from '@mui/x-data-grid';
import { AppBar, Box, createTheme, PaletteColor, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useApi } from '../../../services/HttpService';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { themeStore } from '../../../components/themes/BaseTheme';
import { TabPanel, TabProps } from '../../../components/ui/TabPanel';
import { UserContact } from './UserContact';
import { MemberAccess } from './MemberAccess';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';

interface Member {
  id: number;
  member_number: string;
  first_name: string;
  last_name: string;
  name: string;
}

const mainColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'member_number', headerName: 'Member number', width: 130 },
  { field: 'first_name', headerName: 'First name', width: 130 },
  { field: 'last_name', headerName: 'Last name', width: 130 },
  { field: 'name', headerName: 'Full name', width: 160 },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  // },
];

const subColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'member_number', headerName: 'Member number', width: 130 },
  { field: 'first_name', headerName: 'First name', width: 130 },
  { field: 'last_name', headerName: 'Last name', width: 130 },
  { field: 'name', headerName: 'Full name', width: 160 },
];

export const ManageMembers = () => {
  const theme = useTheme();
  const { api, isLoading } = useApi();
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMember, setSelectedMember] = useState<Member>({} as Member);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [tabIndex, setTabIndex] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const subTableTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: theme.palette.mode === 'dark' ? '#31004a' : '#b9a9d7',
      },
    },
  });

  React.useEffect(() => {
    api("/contacts", "GET").then((res) => {
      if (res) {
        if (res.statusCode === 200) {
          setMembers(res.contacts);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const selected = members.filter(row => selectedIds.has(row.id));
    if (selected.length > 0) {
      setSelectedMember(selected[0]);
    }
  }, [selectedIds]);

  const handleRowSelectionModelChange = (ids: any) => {
    const newSelectedSet = new Set(ids);
    setSelectedIds(newSelectedSet);
  }

  const selectedRows = members.filter(row => selectedIds.has(row.id));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Typography variant='h5' sx={{ my: 2 }}>Manage Members</Typography>
      <Box sx={{ m: 1, p: 3, pt: 1, height: 400, width: "100%" }}>
        <BaseDataGridTable
          rows={members}
          columns={mainColumns}
          loading={isLoading}
          checkboxSelection
          disableMultipleRowSelection
          onRowSelectionModelChange={(ids) => { handleRowSelectionModelChange(ids) }}
        />
      </Box>
      <Typography variant='h6' sx={{ mt: 4 }}>Selected: {selectedMember?.id ? `(${selectedMember.member_number}) ${selectedMember.name}` : ""}</Typography>
      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static"
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black"
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            centered={!isSmallScreen}
            variant={isSmallScreen ? "scrollable" : "standard"}
            textColor="inherit"
            indicatorColor="primary"
            aria-label="manage member tabs"
          >
            {["User", "Access", "Overview"].map((name, index) => (
              <Tab key={index} label={name} sx={{ fontWeight: "bold" }} {...TabProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        <Box sx={{ p: 3, mb: "50vh", minHeight: 400 }}>
          {selectedRows.length > 0 ? (
            <Box sx={{ minHeight: 300 }}>
              <TabPanel value={tabIndex} index={0} sx={{ minHeight: 200 }}>
                <UserContact contact={selectedMember} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} sx={{ minHeight: 200 }}>
                <MemberAccess />
              </TabPanel>
              <TabPanel value={tabIndex} index={2} sx={{ minHeight: 200 }}>
              <BaseDataGridTable
                  theme={subTableTheme}
                  rows={selectedRows}
                  columns={subColumns}
                />
              </TabPanel>
            </Box>
          ) : (
            <>
              <Paper elevation={4} sx={{ width: '100%', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6'>No member selected</Typography>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}