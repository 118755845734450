import { Alert, alpha, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, Paper, styled, Switch, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { treeItemClasses } from '@mui/x-tree-view';
import { Accessgroup, AccessgroupMenuitem, Menuitem, MenuSettings } from '../../../model/System';
import { DashedTreeItem } from '../../../components/ui/DashedTreeItem';

export const MenuitemSettings = () => {
  const theme = useTheme();
  const { api, error, isLoading } = useApi();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [menuSettings, setMenuSettings] = useState<MenuSettings>({} as MenuSettings);
  const [menuSettingsCopy, setMenuSettingsCopy] = useState<MenuSettings>({} as MenuSettings);
  const [hieraricalView, setHieraricalView] = useState<boolean>(false);

  // menuitems
  const [menuitems, setMenuitems] = useState<Menuitem[]>([]);
  const [expandedMenuitems, setExpandedMenuitems] = useState<string[]>([]);
  const [selectedMenuitem, setSelectedMenuitem] = useState<string>("");
  const [clickableExpandedAccessgroups, setClickableExpandedAccessgroups] = useState<string[]>([]);

  // accessgroups
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]);
  const [expandedAccessgroups, setExpandedAccessgroups] = useState<string[]>([]);
  const [selectedAccessgroup, setSelectedAccessgroup] = useState<string>("");
  const [clickableExpandedMenuitems, setClickableExpandedMenuitems] = useState<string[]>([]);

  useEffect(() => {
    api("/systemMenuItemSettings").then(res => {
      if (res.statusCode === 200) {
        handleResponse(res);
      }
    });
  }, []);

  useEffect(() => {
    createMenuitemHirarchy();
    createAccessgroupHirarchy();
  }, [menuSettings]);

  const handleResponse = (res: any) => {
    let menSet: MenuSettings = {
      fields: res?.fields,
      forms: res?.forms,
      menuitems: res?.menuitems ? res.menuitems : [],
      accessgroups: res?.accessgroups ? res.accessgroups : [],
      accessgroupMenuitems: res?.accessgroupMenuitems ? res.accessgroupMenuitems : [],
    }

    setMenuSettings(menSet);
    setMenuSettingsCopy({ ...menSet });
    setClickableExpandedMenuitems(res?.menuitems?.map(menuitem => menuitem.name) || []);
    setClickableExpandedAccessgroups(res?.accessgroups?.map(accessgroup => accessgroup.name) || []);
  }

  // useEffect(() => {
  //   console.log("recheckings statuses, accessgroup", accessgroups.length, accessgroups.length > 0 ? accessgroups[0].name + " " + accessgroups[0].selectedStatus : []);

  //   const updated = menuitems?.map((menuitem) => {
  //     accessgroups?.map((accessgroup) => {
  //       setSelectedStatus(menuitem, accessgroup);
  //     })
  //   })

  //   // setMenuitems([...menuitems]);
  //   // setAccessgroups([...accessgroups]);

  // }, [menuSettings?.accessgroupMenuitems]);

  const createMenuitemHirarchy = () => {
    const menuItemMap = new Map<number, Menuitem>();

    // Initialize the map and set the children array for each item
    menuSettings?.menuitems?.forEach(item => {
      let children: Menuitem[] = [];
      item.children = children;
      item.isMenuitem = true;
      menuItemMap.set(item.id, item);
    });

    const hierarchy: Menuitem[] = [];

    menuSettings?.menuitems?.forEach(item => {
      if (item.parent_id === 0) {
        // no parent
        hierarchy.push(item);
      } else {
        // Find the parent in the map and add the item to its children
        const parent = menuItemMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setMenuitems(hierarchy);
  }

  const createAccessgroupHirarchy = () => {
    const accessgroupMap = new Map<number, Accessgroup>();

    menuSettings?.accessgroups?.forEach(item => {
      let children: Accessgroup[] = [];
      item.children = children;
      item.isAccessgroup = true;
      accessgroupMap.set(item.id, item);
    });

    const hierarchy: Accessgroup[] = [];

    menuSettings?.accessgroups?.forEach(item => {
      if (item.parent_id === 0) {
        hierarchy.push(item);
      } else {
        const parent = accessgroupMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setAccessgroups(hierarchy);
  }

  const reset = () => {
    setMenuSettings(menuSettingsCopy);
  }

  const saveSettings = () => {
    setSuccessMessage("");
    api("/saveSystemMenuItemSettings", "post", { accessgroupMenuitems: menuSettings.accessgroupMenuitems }).then(res => {
      if (res.statusCode === 200) {
        setSuccessMessage("menu settings saved");
        handleResponse(res);
      }
    });
  }

  // handlers for menuitems
  const handleExpandedMenuitemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setSelectedMenuitem("");
    setExpandedMenuitems(itemIds);
  };

  const handleExpandMenuitemsClick = () => {
    setExpandedMenuitems((oldExpanded) =>
      oldExpanded.length === 0
        ? menuSettings?.menuitems?.map(menuitem => menuitem.name)
        : []
    );
  };

  const handleMenuitemSettingsClick = (event, menuitemName) => {
    event.stopPropagation();

    setExpandedMenuitems((oldExpanded) => oldExpanded.filter((item) => item !== menuitemName));
    if (selectedMenuitem === menuitemName) {
      setSelectedMenuitem("");
      return;
    }
    setSelectedMenuitem(menuitemName);
  }

  const handleClickableExpandedAccessgroupsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setClickableExpandedAccessgroups(itemIds);
  };

  // handlers for accessgroups
  const handleExpandedAccessgroupsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setSelectedAccessgroup("");
    setExpandedAccessgroups(itemIds);
  };

  const handleExpandAccessgroupsClick = () => {
    setExpandedAccessgroups((oldExpanded) =>
      oldExpanded.length === 0
        ? menuSettings?.accessgroups?.map(accessgroup => accessgroup.name)
        : []
    );
  };

  const handleAccessgroupsSettingsClick = (event, accessgroupName) => {
    event.stopPropagation();

    setExpandedAccessgroups((oldExpanded) => oldExpanded.filter((item) => item !== accessgroupName));
    if (selectedAccessgroup === accessgroupName) {
      setSelectedAccessgroup("");
      return;
    }
    setSelectedAccessgroup(accessgroupName);
  }

  const handleClickableExpandedMenuitemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setClickableExpandedMenuitems(itemIds);
  };

  const setSelectedStatus = (item: any, other: any) => {
    const isAccessgroup = item.hasOwnProperty('isAccessgroup');
    const isMenuitem = item.hasOwnProperty('isMenuitem');

    // Determine if the current item is selected
    const selected = menuSettings?.accessgroupMenuitems?.some(v =>
      (isAccessgroup && v.accessgroup_id === item.id && v.sys_menuitem_id === other.id) ||
      (isMenuitem && v.accessgroup_id === other.id && v.sys_menuitem_id === item.id)
    );

    let childrenSelectedStatus = [];

    // Check children of the current item
    if (item.children && item.children.length > 0) {
      childrenSelectedStatus.push(...item.children.map(child => setSelectedStatus(child, other)));
    }

    // Combine the current selection with children's status
    const selectedCount = childrenSelectedStatus.filter(status => status === "all").length;
    const partialCount = childrenSelectedStatus.filter(status => status === "partial").length;

    // console.log("name", item.name, "selected", isSelected, "selectedCount", selectedCount, "partialCount", partialCount, "statuses", childrenSelectedStatus)

    // Determine the status based on the selections
    let value = "none";
    if (selected && selectedCount === childrenSelectedStatus.length) {
      value = "all";
    } else if (selected || partialCount > 0 || selectedCount > 0) {
      value = "partial";
    }
    item.selectedStatus = value;
    other.selectedStatus = value;
    return value;
  }

  const editAccessgroupMenuitems = (menuitem: Menuitem, accessgroup: Accessgroup) => {
    let item = menuSettings?.accessgroupMenuitems?.find(v => {
      return v.accessgroup_id === accessgroup.id && v.sys_menuitem_id === menuitem.id
    });

    let updatedAccessgroupMenuitems: AccessgroupMenuitem[] = [];

    if (item) {
      // remove
      updatedAccessgroupMenuitems = menuSettings?.accessgroupMenuitems.filter(
        v => !(v.accessgroup_id === accessgroup.id && v.sys_menuitem_id === menuitem.id)
      );
    } else {
      // check copy, add back or make new
      let newItem = menuSettingsCopy?.accessgroupMenuitems?.find(v => {
        return v.accessgroup_id === accessgroup.id && v.sys_menuitem_id === menuitem.id
      });

      if (!newItem) {
        newItem = {
          id: 0,
          name: accessgroup.name + " " + menuitem.name,
          accessgroup_id: accessgroup.id,
          sys_menuitem_id: menuitem.id,
          status: 0
        }
      }

      updatedAccessgroupMenuitems = [...menuSettings?.accessgroupMenuitems, { ...newItem }];
    }

    setMenuSettings(prev => ({
      ...prev,
      accessgroupMenuitems: updatedAccessgroupMenuitems
    }));
  }

  const editAllSelectedChildren = (item: any, other: any, action: string, newItems: AccessgroupMenuitem[] = [], level: number = 0) => {
    const isAccessgroup = item.hasOwnProperty('isAccessgroup');
    const isMenuitem = item.hasOwnProperty('isMenuitem');

    if (level === 0) {
      newItems = menuSettings?.accessgroupMenuitems;
    }

    if (action === "add") {
      let newItem = menuSettingsCopy?.accessgroupMenuitems?.find(v => {
        if (isAccessgroup) {
          return v.accessgroup_id === item.id && v.sys_menuitem_id === other.id;
        }
        return v.accessgroup_id === other.id && v.sys_menuitem_id === item.id;
      });

      const name = isAccessgroup ? item.name + " " + other.name : other.name + " " + item.name

      if (!newItem) {
        newItem = {
          id: 0,
          name: name,
          accessgroup_id: isAccessgroup ? item.id : other.id,
          sys_menuitem_id: isMenuitem ? item.id : other.id,
          status: 0
        }
      }

      newItems.push({ ...newItem });
    } else if (action === "remove") {
      newItems = newItems.filter(v => {
        if (isAccessgroup) {
          return !(v.accessgroup_id === item.id && v.sys_menuitem_id === other.id);
        }
        return !(v.accessgroup_id === other.id && v.sys_menuitem_id === item.id);
      });
    }

    if (item.children && item.children.length > 0) {
      item.children.forEach(child => {
        newItems = editAllSelectedChildren(child, other, action, newItems, level + 1);
      })
    }

    if (level > 0) {
      return newItems;
    }

    setMenuSettings(prev => ({
      ...prev,
      accessgroupMenuitems: newItems
    }));
  }

  function recursiveSelectTree(item: any, other: any) {
    const isAccessgroup = item.hasOwnProperty('isAccessgroup');
    const isMenuitem = item.hasOwnProperty('isMenuitem');

    const selected = menuSettings?.accessgroupMenuitems?.some(v =>
      (isAccessgroup && v.accessgroup_id === item.id && v.sys_menuitem_id === other.id) ||
      (isMenuitem && v.accessgroup_id === other.id && v.sys_menuitem_id === item.id)
    );

    return (
      <Box key={item.name}>
        <DashedTreeItem
          sx={{ width: '100%' }}
          itemId={item.name}
          label={
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={selected}
                onChange={() => {
                  isAccessgroup ? editAccessgroupMenuitems(other, item) : editAccessgroupMenuitems(item, other)
                }}
                onClick={(event) => event.stopPropagation()}
                disabled={isAccessgroup ? item.name === 'admin' : other.name === 'admin'}
                sx={{ p: 0, mr: 1 }}
              />
              {item.name}
            </Box>
          }
        >
          {item.children.map(child => recursiveSelectTree(child, other))}
        </DashedTreeItem>
      </Box>
    )
  }

  function recursiveSelectFlat(item: any, other: any, level = 0) {
    const isAccessgroup = item.hasOwnProperty('isAccessgroup');
    const isMenuitem = item.hasOwnProperty('isMenuitem');

    const selected = menuSettings?.accessgroupMenuitems?.some(v =>
      (isAccessgroup && v.accessgroup_id === item.id && v.sys_menuitem_id === other.id) ||
      (isMenuitem && v.accessgroup_id === other.id && v.sys_menuitem_id === item.id)
    );

    if (level === 0) {
      setSelectedStatus(item, other);
    }

    return (
      <Box
        key={item.id}
        sx={{
          pt: level === 0 ? 1 : 0,
          borderLeft: level > 0 ? `1px dashed ${alpha(theme.palette.text.primary, 0.4)}` : 'none',
          ml: level > 0 ? 2 : 0,
        }}
      >
        <Box
          sx={{
            borderBottom: item.children.length > 0 ? `1px solid ${alpha(theme.palette.text.primary, 0.2)}` : 'none',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected}
                  disabled={isAccessgroup ? item.name === "admin" : other.name === "admin"}
                  onChange={() => {
                    isAccessgroup ? editAccessgroupMenuitems(other, item) : editAccessgroupMenuitems(item, other)
                  }}
                  sx={{ p: 0, pr: 1, ml: 2 }}
                />}
              label={item.name}
            />
          </FormGroup>
          {item.children.length > 0 && (
            <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={item.selectedStatus === "all"}
                    indeterminate={item.selectedStatus === "partial"}
                    disabled={isAccessgroup ? item.name === "admin" : other.name === "admin"}
                    onChange={() => editAllSelectedChildren(item, other, item.selectedStatus === "none" ? "add" : "remove")}
                    sx={{ p: 0, pl: 1, mr: 2 }}
                  />}
                label={item.selectedStatus === "none" ? 'Select all' : 'Deselect all'}
              />
            </FormGroup>
          )}
        </Box>
        {item.children.map(child => recursiveSelectFlat(child, other, level + 1))}
      </Box>
    )
  }

  function recursiveTree(item: any) {
    const isAccessgroup = item.hasOwnProperty('isAccessgroup');
    const isMenuitem = item.hasOwnProperty('isMenuitem');

    const selectedName = isAccessgroup ? selectedAccessgroup : selectedMenuitem;

    // not done: use to select/deselect all items of other menuitems or accessgroup
    // const otherHasSelectedChildren = isAccessgroup ?
    //   menuitems.map(menuitem => menuitem.selectedStatus !== "none").includes(true) :
    //   accessgroups.map(accessgroup => accessgroup.selectedStatus !== "none").includes(true);

    return (
      <Box key={item.name}>
        <DashedTreeItem
          sx={{ width: '100%' }}
          itemId={item.name}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body1'>{item.name}</Typography>
              <Tooltip title="Edit" onClick={(event) =>
                isAccessgroup ? handleAccessgroupsSettingsClick(event, item.name) : handleMenuitemSettingsClick(event, item.name)
              }>
                <IconButton sx={{ p: 0 }} edge="end" size="small">
                  <ManageAccountsIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
                </IconButton>
              </Tooltip>
            </Box>
          }
        >
          {item.children.map(child => recursiveTree(child))}
        </DashedTreeItem >

        {selectedName === item.name &&
          <Paper elevation={4} sx={{ p: 1 }}>
            <Typography variant='h6' sx={{ mb: 1 }}>{selectedName} - Access</Typography>
            {/* {item.children.length > 0 && (
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={otherHasSelectedChildren === "all"}
                      indeterminate={otherHasSelectedChildren === "partial"}
                      disabled={isAccessgroup ? item.name === "admin" : other.name === "admin"}
                      onChange={() => {
                        // add or remove all items
                      }}
                      sx={{ p: 0, pl: 1, mr: 2 }}
                    />}
                  label={otherHasSelectedChildren ? 'Select all' : 'Deselect all'}
                />
              </FormGroup>
            )} */}

            {hieraricalView ?
              <>
                <SimpleTreeView
                  expandedItems={isAccessgroup ? clickableExpandedMenuitems : clickableExpandedAccessgroups}
                  onExpandedItemsChange={isAccessgroup ? handleClickableExpandedMenuitemsChange : handleClickableExpandedAccessgroupsChange}
                >
                  {isAccessgroup ?
                    <>
                      {menuitems?.map((menuitem: Menuitem) => (
                        recursiveSelectTree(menuitem, item)
                      ))}
                    </> :
                    <>
                      {accessgroups?.map((accessgroup: Accessgroup) => (
                        recursiveSelectTree(accessgroup, item)
                      ))}
                    </>}
                </SimpleTreeView>
              </> :
              <>
                {isAccessgroup ?
                  <>
                    {menuitems?.map((menuitem: Menuitem) => (
                      recursiveSelectFlat(menuitem, item)
                    ))}
                  </> :
                  <>
                    {accessgroups?.map((accessgroup: Accessgroup) => (
                      recursiveSelectFlat(accessgroup, item)
                    ))}
                  </>}
              </>
            }
          </Paper>
        }
      </Box >
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <Typography variant='h5' sx={{ mt: 2 }}>Menu settings</Typography>

      <Alert severity="warning" sx={{ width: "100%" }}>Saving will overwrite changes made by other users since last page load</Alert>

      <InfoArea title='Access groups'>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Button variant='outlined' onClick={handleExpandAccessgroupsClick} sx={{ width: 150, fontWeight: "bold" }}>
            {expandedAccessgroups.length === 0 ? 'Expand all' : 'Collapse all'}
          </Button>
          <FormGroup sx={{ width: 170 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hieraricalView}
                  onChange={(e) => setHieraricalView(e.target.checked)}
                />}
              label={hieraricalView ? "Hierarchy view" : "Flat view"}
            />
          </FormGroup>
        </Box>
        <SimpleTreeView
          expandedItems={expandedAccessgroups}
          onExpandedItemsChange={handleExpandedAccessgroupsChange}
        >
          {accessgroups?.map((accessgroup: Accessgroup) => (
            recursiveTree(accessgroup)
          ))}
        </SimpleTreeView>
      </InfoArea>

      <InfoArea title='Menu items'>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Button variant='outlined' onClick={handleExpandMenuitemsClick} sx={{ width: 150, fontWeight: "bold" }}>
            {expandedMenuitems.length === 0 ? 'Expand all' : 'Collapse all'}
          </Button>
          <FormGroup sx={{ width: 170 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hieraricalView}
                  onChange={(e) => setHieraricalView(e.target.checked)}
                />}
              label={hieraricalView ? "Hierarchy view" : "Flat view"}
            />
          </FormGroup>
        </Box>
        <SimpleTreeView
          expandedItems={expandedMenuitems}
          onExpandedItemsChange={handleExpandedMenuitemsChange}
        >
          {menuitems?.map((menuitem: Menuitem) => (
            recursiveTree(menuitem)
          ))}
        </SimpleTreeView>
      </InfoArea>

      {successMessage && <Alert severity="success" sx={{ width: "100%" }}>{successMessage}</Alert>}
      {error && <Alert severity="error" sx={{ width: "100%" }}>{error}</Alert>}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Button variant='contained' color='darkgrey' onClick={reset}>Reset</Button>
          <Button
            variant='contained'
            color='info'
            onClick={saveSettings}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={22} />}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}